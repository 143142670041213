#video{
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
#navbar{
  height: 80px;
  position: sticky;
  top: 0;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
#logo>img{
  height: 35px;
}
#nav_item{
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

}
#right_nav{
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#content{
  position: fixed;
  bottom: 0;
  margin-bottom: 4%;
  margin-left: 5%;
  color: #f1f1f1;
  width: 100%;
  font-size: 30px;
}
.btn{
  background-color: red;
  border: none;
  font-size: 16px;
  color: whitesmoke;
  padding: 15px 65px;
  border-radius: 6px;
}
.btn1{
  background-color: transparent;
  border: none;
  border: 1px solid whitesmoke;
  font-size: 16px;
  color: whitesmoke;
  padding: 13px 35px;
  margin-left: 2%;
  border-radius: 6px;
}
.btn2{
  background-color: red;
  border: none;
  color: whitesmoke;
  padding: 12px 20px;
  border-radius: 6px;
}